<template>
  <div class="popup_wrap" style="width:500px;">
    <!-- 팝업사이즈 참고 : 800*600  popup_wrap -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="frm_bldo_payment">
      <div class="popup_cont">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.cntrTareWeight.001') }}</h1><!--Container tare Weight -->

        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_col">
            <colgroup>
              <col width="10%">
              <col width="50%">
              <col width="40%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.cntrTareWeight.002') }}</th><!-- No. -->
                <th>{{ $t('msg.cntrTareWeight.003') }}</th><!-- Container No.  -->
                <th>{{ $t('msg.cntrTareWeight.004') }}</th><!-- weignt -->
              </tr>
            </thead>
            <tbody v-if="cntrTareWeight.length > 0">
              <tr v-for="( item, i ) in cntrTareWeight" :key="i">
                <td>{{ i+1 }}</td>
                <td>{{ item.cntrNo }}</td>
                <td>{{ $ekmtcCommon.changeNumberFormat(item.actTareWt, { isComma: true }) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">{{ $t('msg.cntrTareWeight.005') }}</td><!-- 등록된 내용이 없습니다. -->
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'ContainerTareWeightPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          keyword: '',
          isValidCheck: true
        }
      }
    }
  },
  computed: {
    ...rootComputed
  },
  data: function () {
    return {
      keyword: this.parentInfo.keyword,
      cntrTareWeight: []
    }
  },
  mounted () {
    if (this.keyword !== undefined) {
      this.getContainerTareWeight()
    }
  },
  methods: {
    async getContainerTareWeight () {
      if (this.$ekmtcCommon.isEmpty(this.keyword)) return

      let keyword = this.keyword.replace('KMTC', '')
      await trans.findContainerTareWeight(keyword)
        .then((response) => {
          if (response.data != null && response.data.length > 0) {
            this.cntrTareWeight = response.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
