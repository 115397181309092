var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "frm_bldo_payment" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.cntrTareWeight.001"))),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(0),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.cntrTareWeight.002")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.cntrTareWeight.003")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.cntrTareWeight.004")))]),
                ]),
              ]),
              _vm.cntrTareWeight.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.cntrTareWeight, function (item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [_vm._v(_vm._s(i + 1))]),
                        _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                item.actTareWt,
                                { isComma: true }
                              )
                            )
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "5" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.cntrTareWeight.005"))),
                      ]),
                    ]),
                  ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "50%" } }),
      _c("col", { attrs: { width: "40%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }